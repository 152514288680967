import React, { useState } from "react";

import styles from "../styles/header.module.scss";

import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineCloseSquare } from "react-icons/ai";

const Header = ({ setPage }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuToggler = () => setMenuOpen((p) => !p);

    return (
        <div className={styles.header}>
            <div className={styles.header__content}>
                <div>
                    <span className={styles.logo}>VetLive</span>
                </div>
                <div>
                    <nav className={`${styles.nav} ${menuOpen ? styles[`nav--open`] : {}}`}>
                        <nav className={styles.nav__item} href={"/"} onClick={() => setPage(0)}>
                            Ana Sayfa
                        </nav>
                        <nav className={styles.nav__item} onClick={() => setPage(3)}>
                            Hakkımızda
                        </nav>
                        {/* <a className={styles.nav__item} onClick={() => setPage(2)}>
                            What Is This?
                        </a>
                        <a className={styles.nav__item} onClick={() => setPage(3)}>
                            About Us
                        </a> */}
                        {/* <div className={styles.nav__button__container}>
                            <Button />
                        </div> */}
                    </nav>
                </div>
                <div>
                    <div className={styles.header__button__container}>
                        <Button />
                    </div>
                    <button className={styles.header__toggler} onClick={menuToggler}>
                        {!menuOpen ? <BiMenuAltRight /> : <AiOutlineCloseSquare />}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Button = () => {
    return <button className={styles.button}>Click me</button>;
};

export default Header;