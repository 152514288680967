import React from "react";
import '../screens/styles/AboutUs.css';
import styles from '../styles/aboutme.module.scss';
import sertac from '../assets/image/sertac.png'

function AboutUs() {
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <img className={styles.picAboutUs} src={sertac} alt="test" />
                <div>
                    <div className={styles.info}>
                        <p className={styles.textTitleAboutUs}>Sertaç Duymaz</p>
                        <p className={styles.textAboutUs}>
                            2011 yılında İstanbul Üniversitesi Veterinerlik Fakültesi'nden mezun oldum.
                            Yaklaşık 12 senedir veteriner hekim olarak iş hayatıma devam etmekteyim.
                        </p>
                        <p className={styles.textAboutUs}>sertacduymaz@gmail.com</p>
                    </div>
                </div>
            </div>
            {/* <div className="frameAboutUs">
                <img className='picAboutUs' src={rec} alt="test" />
                <div>
                    <p className="textTitleAboutUs">Resul Ekrem Çoban</p>
                    <p className="textAboutUs">
                        I graduated from computer engineering in 2013 and finished master degree about 
                        information technologies from Galatasaray University.
                        In addition, I have been working as a software developer since 2014. 
                        My professional field is react-native. I had been working as an Android developer before, 
                        but I have been typing code since 2018 with react-native.</p>
                    <p className="textAboutUs">resulekremcoban@gmail.com</p>
                </div>
            </div> */}
        </div>
    )
}

export default AboutUs