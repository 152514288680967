import React, { useEffect, useState } from "react";
import styles from '../styles/main.module.scss'
// import '../screens/styles/NewHome.css';
import google from '../assets/image/google.png'
import apple from '../assets/image/apple.png'
import banner from '../assets/image/vetlive.png'
import muayene from '../assets/image/muayene.jpg'
import cerrahi from '../assets/image/cerrahi.jpg'
import dahiliye from '../assets/image/dahiliye.png'
import laboratuvar from '../assets/image/laboratuvar.jpg'
import asi from '../assets/image/asi.png'
import yogunBakim from '../assets/image/yogunBakim.jpg'
import banyo from '../assets/image/banyo.jpg'
import kuafor from '../assets/image/kuafor.jpg'
import petShop from '../assets/image/petShop.png'
import mikroCip from '../assets/image/mikroChip.jpg'
import rontgen from '../assets/image/rontgen.png'
import pingPong from '../assets/image/ping_pong.png'
import tennis from '../assets/image/tennis.png'
import volleyball from '../assets/image/volleyball.png'
import yoga from '../assets/image/yoga.png'

import Map from '../components/Map'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Container, Button, Link } from 'react-floating-action-button'
import { CiLocationOn } from "react-icons/ci";

const activities = [
    {id: 0, name: 'Muayene'}, 
    {id: 1, name: 'Cerrahi'},
    {id: 2, name: 'Dahiliye'}, 
    {id: 3, name: 'Laboratuvar'}, 
    {id: 4, name: 'Aşı'},
    {id: 5, name: 'Röntgen'},
    {id: 6, name: 'Banyo'},
    {id: 7, name: 'Kuafor'},
    {id: 8, name: 'Pet Shop'},
    {id: 9, name: 'Mikro Çip'},
    {id: 10, name: 'Yoğun Bakım'},
    {id: 11, name: 'Ping Pong'},
    {id: 12, name: 'Tennis'},
    {id: 13, name: 'Volleyball'},
    {id: 14, name: 'Yoga'},
   ];

function selectActivity (item) {
    switch (item) {
        case 'Muayene':
            return muayene
        case 'Cerrahi':
            return cerrahi
        case 'Dahiliye':
            return dahiliye
        case 'Laboratuvar':
            return laboratuvar
        case 'Aşı':
            return asi
        case 'Yoğun Bakım':
            return yogunBakim
        case 'Banyo':
            return banyo
        case 'Kuafor':
            return kuafor
        case 'Pet Shop':
            return petShop
        case 'Mikro Çip':
            return mikroCip
        case 'Röntgen':
            return rontgen
        case 'Ping Pong':
            return pingPong
        case 'Tennis':
            return tennis
        case 'Volleyball':
            return volleyball
        case 'Yoga':
            return yoga
    }
}

function frameActivity (item) {
    return (
        <div className={styles.frameMain}>
            <img className={styles.picMain} src={selectActivity(item.name)} alt="test" />
            <h4 className={styles.picMain__title}>{item.name}</h4>
        </div>
    )
}

function Main () {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [screenWidth])

    const detectSize = () => {
        setScreenWidth(window.innerWidth)
    }

    const goToMap = () => { 
        window.location.href = "https://www.google.com/maps/place/Altay%C3%A7e%C5%9Fme,+VetLive+Veteriner+Muayenehanesi,+Ba%C4%9Fdat+Cd.+No:+357,+34843+Maltepe%2F%C4%B0stanbul/@40.9300987,29.1291003,17z/data=!4m6!3m5!1s0x14cac715e6c2b851:0xe36b654d05e9bef!8m2!3d40.9300987!4d29.1291003!16s%2Fg%2F11jz8rrxwc" 
        console.log('BUTTON') 
    }

    return (
        <div className={styles.main}>
            <div className={styles.container1}>
                <div className={styles.container1__frame1}>
                    <img className={styles.container1__frame1__img} src={banner} alt="test" />
                </div>
                <div className={styles.container1__frame2}>
                    <p className={styles.info1}>
                        VetLive Veteriner Muayenehanesi olarak; 2022 yılı itibariyle hizmet vermeye başlamış olup,
                        daima teknolojik gelişmeleri yakından takip etmekteyiz. İstanbul Maltepe lokasyonunda hizmet
                        verirken etik değerlerimize sahip çıkan anlayışımızla, sevimli dostlarımızın sağlık sorunlarına
                        kalıcı ve kesin çözümler üretiyor ihtiyaçlarınızı doğru şekilde karşılamaya özen gösteriyoruz.
                        <p style={{ fontWeight: 'bold', paddingTop: 10, }}>Muayene - Check Up</p>
                        Kliniğimizde kedi, köpek ve süs kuşları başta olmak üzere tavşan, hamster, sürüngenler, yabani kanatlılar,
                        maymun, sincap, gibi hayvanların genel muayeneleri yapılmaktadır. 
                    </p>
                </div>
            
                {/* <div className={styles.frame4}>TEST 4</div> */}

            </div>
            <div className={styles.container2}>
                <div className={styles.frame1}>
                    <p className={styles.info2}>
                        <p style={{ fontWeight: 'bold', paddingTop: 10, }}>Aşı Uygulaması</p>
                        Kliniğimiz bünyesinde, evcil hayvanların her türlü ithal aşıları yapılmakta ve kullandığımız hasta kayıt
                        programı sayesinde aşı tekrarlama zamanları hayvan sahiplerine telefon ya da sms yoluyla bildirilmektedir.

                        <p style={{ fontWeight: 'bold', paddingTop: 10, }}>Mikroçip Uygulaması</p>
                        Veteriner Kliniğimiz bünyesinde, gerçekleştirilen mikroçip uygulaması, aşı uygulaması gibi kısa bir süre
                        içinde küçük dostunuzun deri altına özel enjektörü ile yerleştirilmektedir.
                    </p>
                    <p className={styles.info2}>
                        <p style={{ fontWeight: 'bold' }}>Laboratuvar</p>
                        Kliniğimiz bünyesinde doğru teşhise ulaşmak adına gerekli laboratuvar hizmetimizle  hematolojik, biyokimyasal,
                        serolojik, mikrobiyolojik ve patolojik muayeneler yapılmaktadır.

                        <p style={{ fontWeight: 'bold', paddingTop: 10, }}>CR Röntgen</p>
                        Kliniğimiz bünyesinde bilgisayarlı röntgen (CR) cihazımızla direk ve radyopak maddeli grafiler alınmaktadır.
                        Ayrıca kliniğimiz bünyesinde, penn hip yöntemi ile kalça displazilerinin erken teşhisleri yapılabilmektedir.
                    </p>
                </div>
            </div>

            <div className={styles.container3}>
                {frameActivity(activities[0])}
                {frameActivity(activities[1])}
                {frameActivity(activities[2])}
                {frameActivity(activities[3])}
                {frameActivity(activities[4])}
                {frameActivity(activities[5])}
            </div>
            <br/>
            <div className={styles.container3}>
                {frameActivity(activities[6])}
                {frameActivity(activities[7])}
                {frameActivity(activities[8])}
                {frameActivity(activities[9])}
                {frameActivity(activities[10])}
                {/* {frameActivity(activities[11])} */}
            </div>
            <br />
            <br />
            <br />
            <Map
              isMarkerShown
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAOlzINha1PQr6neBdOPeiCTT5HOBg0alE&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
            <FloatingWhatsApp
                phoneNumber={"+905333722607"}
                chatMessage={"Size nasıl yardımcı olabiliriz?"}
                accountName={"Vetlive"}
                avatar={require('../assets/image/vetlive.png')}
                statusMessage={"1 dk içinde yanıt..."}
                placeholder={"Mesajınız..."}
                allowClickAway={true}
            />
            {/* <div className={styles.container3}>
                {frameActivity(activities[12])}
                {frameActivity(activities[13])}
                {frameActivity(activities[14])}
            </div> */}
             <Container styles={{bottom: 100, right: 13,}}>
                <Button
                    styles={{backgroundColor: 'gray', width: 60, height: 60}}
                    onClick={() => goToMap()} 
                    >
                        <CiLocationOn size={40} color={'white'} />
                </Button>
            </Container>
        </div>

    )
}

export default Main;