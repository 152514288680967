import React from "react";
import styles from '../styles/whatIsIt.module.scss';
import '../screens/styles/NewHome.css';

function WhatIsIt() {
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <ul>
                    <li>
                        <p className={styles.question}>What is Let’s Get Up and how it works?</p>
                        <p className={styles.answer}>Let's Get Up is an application where you can find your teammate or opponent in a short time by creating the sports activity you want, wherever you want, or participate in different sports activities that will your interest.</p>
                    </li>
                </ul>
            </div>
            <br/>
            <br/>
            <div className={styles.container}>
                <ul>
                    <li>
                        <p className={styles.question}>Profile</p>
                        <p className={styles.answer}>In order to create an activity on the Let's Get Up platform, you need to register with your Google or Apple account and create a profile. Moreover, you must share your email via Apple Sign In.</p>
                    </li>
                </ul>
            </div>
            <br/>
            <br/>
            <div className={styles.container}>
                <ul>
                    <li>
                        <p className={styles.question}>Join to activities</p>
                        <p className={styles.answer}>You can choose the one that suits you from the activities you will see on the homepage and send a participation request. You can participate in the event with the approval of the activity owner.</p>
                    </li>
                </ul>
            </div>
            <br/>
            <br/>
            <div className={styles.container}>
                <ul>
                    <li>
                        <p className={styles.question}>Contact</p>
                        <p className={styles.answer}>If you are accepted to the activity, you can contact the activity owner via your e-mail account.</p>
                    </li>
                </ul>
            </div>
            <br/>
            <br/>
            <div className={styles.container}>
                <ul>
                    <li>
                        <p className={styles.question}>Leaving the activities</p>
                        <p className={styles.answer}>You can leave before the activity you joined takes place via the app. In this case your score does not affect negatively. On the other hand, if you do not join an activity, you score affects negatively.</p>
                    </li>
                </ul>
            </div>
            <br/>
            <br/>
            <div className={styles.container}>
                <ul>
                    <li>
                        <p className={styles.question}>Scoring</p>
                        <p className={styles.answer}>After the activity is over, you are rated by other participants.</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default WhatIsIt