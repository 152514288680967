import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import './styles/NewHome.css';
// import './styles/Main.css';
import Main from '../components/Main';
import Header from '../components/Header';
import HowToUse from '../components/HowToUse';
import WhatIsIt from '../components/WhatIsIt';
import AboutUs from '../components/AboutUs';
import header from '../assets/image/letsgetup.png'

function swithPage(item) {
    switch (item) {
        case 0:
            return <Main />
        case 1:
            return <HowToUse />
        case 2:
            return <WhatIsIt />
        case 3:
            return <AboutUs />
    }
}

function NewHome() {
    const [page, setPage] = useState(0);

    return (
        <div>
            <Header setPage={ setPage } />
        {/* //     <div className='header'>
        //         <img className='headerPic' src={header} alt="test" />
        //         <nav>
        //             <ul>
        //                 <li>
        //                     <nav className={page === 0 ? 'titleNavBold' : 'titleNav'} onClick={() => setPage(0)} >Home</nav>
        //                 </li>
        //                 <li>
        //                     <nav className={page === 1 ? 'titleNavBold' : 'titleNav'} onClick={() => setPage(1)} >How To Use</nav>
        //                 </li>
        //                 <li>
        //                     <nav className={page === 2 ? 'titleNavBold' : 'titleNav'} onClick={() => setPage(2)} >What Is It?</nav>
        //                 </li>
        //                 <li>
        //                     <nav className={page === 3 ? 'titleNavBold' : 'titleNav'} onClick={() => setPage(3)} >About Us</nav>
        //                 </li>
        //             </ul>
        //         </nav>
        //     </div> */}

        {swithPage(page)}       
        
        </div>
        
    )
}


export default NewHome;