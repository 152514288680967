import React from "react";
import '../screens/styles/NewHome.css';
import styles from '../styles/howToUse.module.scss';
import createActivity from '../assets/image/create_activity_en.png'
import joinActivity from '../assets/image/join_activity_en.png'
import ReactPlayer from 'react-player'

function HowToUse() {
    return (
        <div className={styles.main}>
            <div className={styles.container1}>
                <ReactPlayer url='https://www.youtube.com/watch?v=qgXRWeE452I' />
            </div>
            <div className={styles.container2}>
                <div className={styles.container2__col1}>
                    <img className={styles.container2__pic} src={createActivity} alt="test" />
                </div>
                <div className={styles.container2__col2}>
                    <img className={styles.container2__pic} src={joinActivity} alt="test" />
                </div>
            </div>

            {/* <div className="mainHowToUse" >
                <ReactPlayer url='https://www.youtube.com/watch?v=qgXRWeE452I' />
            </div>
            <div className='rowHowToUse'>
                <img className='picActivity1' src={createActivity} alt="test" />
                <img className='picActivity2' src={joinActivity} alt="test" />
            </div> */}
        </div>
    )
}

export default HowToUse