import React from "react";
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    withScriptjs
} from "react-google-maps";

const Map = () => {
    return (
        <div style={{ height: '20vh', width: '100%' }}>
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: 40.93026889955607, lng: 29.12912175546154 }}
        >
            <Marker label="Vetlive" labelStyle={{color: 'red'}} position={{ lat: 40.93026889955607, lng: 29.12912175546154 }} />
            </GoogleMap>
        </div>
    ) 
}

const WrappedMap = withScriptjs(withGoogleMap(Map))
export default WrappedMap;