import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './screens/Home';
import HowToUse from './components/HowToUse';
// import Share from './screens/Share';
import Header from './components/Header';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/share" element={<Share />} /> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
